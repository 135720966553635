<template>
  <div>
    <el-popover v-model="requestsPopover" trigger="manual" placement="bottom-start" popper-class="p-0 mt-2">
      <RequestsButtonPopover
        v-if="requestsPopover"
        :task-id="taskId"
        :target-business-options="targetBusinessOptions"
        :requests="sortedRequests"
        :templates="templates"
        @close="close"
        @create-request="handleCreateRequest"
        @show-close-request-modal="showCloseRequestModal"
        @show-verdict-modal="handleShowVerdictModal"
        @template-selected="onTemplateSelected"
      />
      <template #reference>
        <div @click="requestsPopover = !requestsPopover">
          <RequestsButton
            :open="requestsPopover"
            :task-id="taskId"
            :target-business-options="targetBusinessOptions"
            :requests="sortedRequests"
          />
        </div>
      </template>
    </el-popover>
    <CloseRequestModal
      v-if="sortedRequests[requestToCloseIndex]"
      :request="sortedRequests[requestToCloseIndex]"
      @close="requestToCloseIndex = null"
      @close-request="handleCloseRequest"
    />
    <ActionsModal
      v-if="responseVerdict"
      :toggle-dialog="!!responseVerdict"
      :title="responseVerdictTitle"
      :confirm-button-text="responseVerdictConfirmButtonText"
      :cancel-button-text="$t('commons.cancel')"
      dialog-type="info"
      @on-cancel="closeVerdictModal"
      @on-close="closeVerdictModal"
      @on-confirm="handleVerdict"
    >
      <template #subtitle>
        <p>
          {{ sortedRequests[requestToUpdateIndex].sourceBusiness.name }}
          <ExchangeIcon class="mx-1" />
          {{ sortedRequests[requestToUpdateIndex].targetBusiness.name }}
        </p>
      </template>
      <template #content>
        <p v-if="responseVerdict === VERDICT.VALID" class="text-typography-primary">
          {{ $t('requests.verdictModalDescription') }}
        </p>
        <div v-else class="text-break">
          <p class="text-typography-primary"><span class="text-danger">*</span>{{ $t('requests.reason') }}</p>
          <el-input
            v-model="reason"
            class="mt-1"
            :class="{ 'error-border': hasFailedValidation }"
            type="textarea"
            resize="none"
            :autosize="{ minRows: 3, maxRows: 10 }"
          />
          <p v-if="hasFailedValidation" class="text-danger">{{ $t('requests.fieldRequired') }}</p>
        </div>
      </template>
    </ActionsModal>
  </div>
</template>
<script>
import { ref, computed } from 'vue';

import { useBusinesses } from '@/modules/auth';
import { useUser } from '@/modules/auth';
import { CloseRequestModal } from '@/modules/requests';
import { ActionsModal } from '@/modules/core';
import { ExchangeIcon } from '@/assets/icons';

import RequestsButton from './RequestsButton';
import RequestsButtonPopover from './RequestsButtonPopover';
import { useRequests } from '../composition';

const VERDICT = {
  VALID: 'valid',
  INVALID: 'invalid',
};

export default {
  name: 'RequestsButtonContainer',
  components: { RequestsButton, RequestsButtonPopover, CloseRequestModal, ActionsModal, ExchangeIcon },
  props: {
    taskId: { type: String, required: true },
    sourceBusinessId: { type: String, required: true },
    targetBusinesses: { type: Array, default: () => [] },
    templates: { type: Array, required: false, default: () => [] },
  },
  setup(props) {
    const { user } = useUser();
    const { requests, createRequest, closeRequest, updateResponse } = useRequests(
      computed(() => ({ taskId: props.taskId }))
    );
    const { businesses } = useBusinesses();

    const targetBusinessOptions = computed(() =>
      props.targetBusinesses.map((targetBusinessId) => businesses.value.find(({ id }) => targetBusinessId === id))
    );

    return {
      requestsPopover: ref(props.templates?.some((template) => template.selected)),
      sortedRequests: computed(() => {
        const openRequests = requests.value.filter(({ closed }) => !closed);
        const closedRequests = requests.value.filter(({ closed }) => closed);
        return [...openRequests, ...closedRequests];
      }),
      createRequest,
      closeRequest,
      targetBusinessOptions,
      businesses,
      user,
      requestToCloseIndex: ref(null),
      requestToUpdateIndex: ref(null),
      responseToUpdateIndex: ref(null),
      VERDICT,
      responseVerdict: ref(null),
      responseVerdictTitle: ref(null),
      responseVerdictConfirmButtonText: ref(null),
      updateResponse,
      reason: ref(null),
      hasFailedValidation: ref(false),
    };
  },
  computed: {
    requestByAction() {
      return this.templates.some((template) => template.selected);
    },
  },
  watch: {
    templates: {
      handler() {
        const isSelected = this.templates.some((template) => template.selected);
        this.requestsPopover = isSelected || this.requestsPopover;
      },
      deep: true,
    },
    taskId() {
      this.requestsPopover = false;
    },
  },
  methods: {
    onTemplateSelected(type) {
      this.$emit('template-selected', type);
    },
    close() {
      this.requestsPopover = false;
      this.requestToCloseIndex = null;
      this.$emit('close');
    },
    handleCreateRequest(createParams) {
      const sourceBusiness = this.businesses.find((business) => business.id === this.sourceBusinessId);
      const targetBusiness = this.businesses.find((business) => business.id === createParams.targetBusinessId);
      this.createRequest(
        {
          createParams: { ...createParams, sourceBusinessId: this.sourceBusinessId, taskId: this.taskId },
        },
        sourceBusiness,
        targetBusiness,
        this.user
      );
      this.$emit('template-selected', null);
    },
    showCloseRequestModal(requestIndex) {
      this.requestToCloseIndex = requestIndex;
    },
    async handleCloseRequest(answer) {
      const requestToClose = this.sortedRequests[this.requestToCloseIndex];
      this.closeRequest(requestToClose.id, answer, this.user);
      this.requestToCloseIndex = null;
    },
    handleShowVerdictModal({ verdict, requestIndex, responseIndex }) {
      this.requestToUpdateIndex = requestIndex;
      this.responseToUpdateIndex = responseIndex;
      if (verdict === VERDICT.VALID) {
        this.responseVerdictTitle = this.$t('requests.validResponse');
        this.responseVerdictConfirmButtonText = this.$t('requests.markAsValid');
      } else {
        this.responseVerdictTitle = this.$t('requests.invalidResponse');
        this.responseVerdictConfirmButtonText = this.$t('commons.save');
      }
      this.responseVerdict = verdict;
    },
    closeVerdictModal() {
      this.requestToUpdateIndex = null;
      this.responseToUpdateIndex = null;
      this.responseVerdict = null;
      this.responseVerdictTitle = null;
      this.responseVerdictConfirmButtonText = null;
      this.hasFailedValidation = false;
      this.reason = null;
    },
    async handleVerdict() {
      const request = this.sortedRequests[this.requestToUpdateIndex];
      try {
        if (this.responseVerdict === VERDICT.VALID) {
          this.closeRequest(request.id, undefined, this.user);
        } else {
          if (!this.reason) {
            this.hasFailedValidation = true;
            return;
          }
          this.updateResponse(
            {
              requestId: request.id,
              index: this.responseToUpdateIndex,
              updateParams: { reject: { text: this.reason } },
            },
            this.user
          );
        }
        this.closeVerdictModal();
      } catch {
        this.$message.error(this.$t('commons.messages.action.error'));
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

:deep(.error-border textarea) {
  border-color: $error;
}
</style>
